import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

import { Web3Provider } from "./Web3Provider/Web3Provider";
import { RevealStateProvider } from "@/context/RevealState";
import { useLoading } from "@/context/LoadingState";
import { GlobalStyle } from "@/assets/style/globalStyles";

import { Header } from "./Header/Header";
import { Main } from "./Main/Main";
import { Footer } from "./Footer/Footer";
import { Oval } from "react-loader-spinner";

import "react-toastify/dist/ReactToastify.css";

function App() {
  const { loading } = useLoading();
  useEffect(() => {
    window.Buffer = window.Buffer || require("buffer").Buffer;
  }, []);

  return (
    <Web3Provider>
      <RevealStateProvider>
        <GlobalStyle />
        <Header />
        <Main />
        <Footer />
        <Oval
          height={60}
          width={60}
          color="#ffffff"
          wrapperClass="overlay loader"
          visible={loading}
          ariaLabel="oval-loading"
          secondaryColor="#DAB387dfc"
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
        <ToastContainer newestOnTop />
      </RevealStateProvider>
    </Web3Provider>
  );
}

export default App;
