import { useMediaQuery } from 'usehooks-ts';
import logo from '@/assets/images/logo.svg';
import border from '@/assets/images/header-border.svg';
import { StyledHeader, BorderDecorator } from './Header.style';
import { AddressButton, ConnectionButton } from '../common';

export const Header = () => {
  const isTablet = useMediaQuery('(min-width: 768px)');

  return (
    <StyledHeader>
      <div className='content'>
        {isTablet && (
          <>
            <BorderDecorator src={border} alt='' />
            <img src={logo} alt='sparta dex' className='logo' />
          </>
        )}
        <AddressButton />
        <ConnectionButton hide={!isTablet} />
      </div>
    </StyledHeader>
  );
};
