import { ModalButton } from "../WalletButtons/WalletButtons.style";
import {
  ButtonsContainer,
  CloseBtn,
  Container,
  Message,
  Title,
} from "./ConfirmationModal.styles";

import closeIcon from "@/assets/images/close-icon.svg";

type Props = {
  onClose: () => void;
};

export const ConfirmationModal = ({ onClose }: Props) => {
  const redirectToOpenSea = () => {
    onClose();
    window.open("https://opensea.io/collection/spartadex-revealed", "_blank");
  };

  return (
    <Container>
      <CloseBtn className="closeBtn" onClick={onClose}>
        <img src={closeIcon} alt="close" />
      </CloseBtn>
      <Title>Congratulations Warrior!</Title>
      <Message>
        You've freed your Spartans from the cold stone! Now run to give them a
        warm welcome or break the next ones. Tell me, are they rare? Have you
        freed the Gods already?
      </Message>
      <ButtonsContainer>
        <ModalButton onClick={redirectToOpenSea}>
          VIEW ON <br />
          OPENSEA
        </ModalButton>
        <ModalButton onClick={onClose}>BREAK NEXT</ModalButton>
      </ButtonsContainer>
    </Container>
  );
};
