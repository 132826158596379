import { BigNumber } from "ethers";
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { REVEALER_CONTRACT_ADDRESS } from "@/constants/constants";

import revealerAbi from "@/assets/abi/revealerAbi.json";

type RevealTokensProps = {
  onSuccess: () => void;
  onError?: () => void;
  tokens: BigNumber[];
};

export const useRevealTokens = ({
  onError,
  tokens,
  onSuccess,
}: RevealTokensProps) => {
  const prepareWrite = usePrepareContractWrite({
    address: REVEALER_CONTRACT_ADDRESS,
    abi: revealerAbi,
    functionName: "reveal",
    args: [tokens],
    onError,
  });

  const reveal = useContractWrite(prepareWrite.config);

  const revealTx = useWaitForTransaction({
    hash: reveal?.data?.hash,
    enabled: !!reveal?.data?.hash,
    onError,
    onSuccess,
  });

  const isLoading = reveal.isLoading || revealTx.isLoading;
  const isError = reveal.isError || revealTx.isError;
  const isSuccess = reveal.isSuccess && revealTx.isSuccess;

  return {
    startReveal: reveal.write,
    revealLoading: isLoading,
    revealIsError: isError,
    revealPrepareIsError: prepareWrite.isError,
    revealIsSuccess: isSuccess,
  };
};
