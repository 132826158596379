import React, { useState } from 'react';

interface LoadingValues {
  loading: boolean;
  setLoading: (state: boolean) => void;
}
const contextDefaultValues = {
  loading: false,
  setLoading: () => {},
};

export const Loading = React.createContext<LoadingValues>(contextDefaultValues);

export const useLoading = () => {
  const context = React.useContext(Loading);
  if (context === undefined) {
    throw new Error('useLoading was used outside of its Provider');
  }
  return context;
};

export const LoadingProvider = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Loading.Provider
      value={{
        loading,
        setLoading,
      }}>
      {children}
    </Loading.Provider>
  );
};
