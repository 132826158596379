import React, { useState } from "react";

interface RevealStateValues {
  refresh: boolean;
  setRefresh: (state: boolean) => void;
  amount: number;
  setAmount: (state: number) => void;
  increase: () => void;
  decrease: () => void;
  maxAmount: number;
  setMaxAmount: (state: number) => void;
}
const contextDefaultValues = {
  refresh: false,
  setRefresh: () => {},
  amount: 1,
  setAmount: () => {},
  increase: () => {},
  decrease: () => {},
  maxAmount: 10,
  setMaxAmount: () => {},
};

export const RevealState =
  React.createContext<RevealStateValues>(contextDefaultValues);

export const useRevealState = () => {
  const context = React.useContext(RevealState);
  if (context === undefined) {
    throw new Error("useRevealState was used outside of its Provider");
  }
  return context;
};

export const RevealStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [refresh, setRefresh] = useState<boolean>(false);
  const [amount, setAmount] = useState(1);
  const [maxAmount, setMaxAmount] = useState(10);

  const increase = () => {
    setAmount((prev) => prev + 1);
  };
  const decrease = () => {
    setAmount((prev) => prev - 1);
  };

  return (
    <RevealState.Provider
      value={{
        refresh,
        setRefresh,
        amount,
        setAmount,
        increase,
        decrease,
        maxAmount,
        setMaxAmount,
      }}
    >
      {children}
    </RevealState.Provider>
  );
};
