import styled, { css, keyframes } from "styled-components";
import loadingBackground from "@/assets/images/loading-background.svg";
import { flexCenter } from "@/assets/style/globalStyles";
import { SIZES } from "@/assets/style/mediaQueries";

const dot1Animation = keyframes`
  0% { opacity: 0;}
  25% { opacity: 0; }
  26% { opacity: 1; }
  100% { opacity: 1;}
`;

const dot2Animation = keyframes`
  0% { opacity: 0;}
  50% { opacity: 0; }
  51% { opacity: 1; }
  100% { opacity: 1;}
`;

const dot3Animation = keyframes`
  0% { opacity: 0;}
  75% { opacity: 0; }
  76% { opacity: 1; }
  100% { opacity: 1; }
`;

export const NotificationWrapper = styled.div<{
  notWhitelisted?: boolean;
  fundsErr?: boolean;
  isPageCentered?: boolean;
}>`
  width: 250px;
  height: 137px;
  background: url(${loadingBackground});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  ${flexCenter};
  column-gap: 30px;
  padding: 15px;
  margin: 0 auto;
  width: 296px;
  height: 137px;
  border-radius: 8px;
  z-index: 9999;
  ${({ isPageCentered }) =>
    isPageCentered &&
    css`
      position: fixed;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
  ${SIZES.tablet} {
    column-gap: 12px;
    padding: 10px;
    width: 178px;
    height: 78px;
    border-radius: 4px;
  }
  ${SIZES.laptop} {
    column-gap: 20px;
    padding: 10px;
    width: 296px;
    height: 137px;
  }
`;

export const Message = styled.div`
  font-family: "avara800";
  font-size: 19px;
  line-height: 127.5%;
  color: #96683f;
  text-align: left;
  background: transparent;
  ${SIZES.tablet} {
    font-size: 12px;
  }
  ${SIZES.laptop} {
    font-size: 19px;
  }
  & .dot1 {
    animation-name: ${dot1Animation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  & .dot2 {
    animation-name: ${dot2Animation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  & .dot3 {
    animation-name: ${dot3Animation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
`;

export const Icon = styled.img`
  width: 90.85px;
  height: 76.66px;

  ${SIZES.tablet} {
    width: 67.04px;
    height: 56.56px;
  }
  ${SIZES.laptop} {
    width: 102.83px;
    height: 86.77px;
  }
`;

export const CloseBtn = styled.button`
  width: 13px;
  height: 13px;
  ${flexCenter};
  position: absolute;
  right: 12px;
  top: 10px;
  background: transparent;

  ${SIZES.tablet} {
    width: 15px;
    height: 12px;
    transform: scale(0.6);
    right: 8px;
    top: 2px;
  }
  ${SIZES.laptop} {
    width: 13px;
    height: 13px;
    transform: scale(1);
    right: 12px;
    top: 10px;
  }
`;
