import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { BigNumber } from "ethers";
import { useReadApprovalState } from "@/services/useReadContract";

import { Notifications } from "@/components/common/Notifications/Notifications";
import { StyledConnectionBtn } from "@/components/common/WalletButtons/WalletButtons.style";
import { ButtonsWrapper, Container, MintedCount } from "./Reveal.style";
import { useApproveTransaction } from "@/services/useApproveTransaction";
import { SendRevealTransation } from "./SendRevealTransaction";
import { ConfirmationModal } from "@/components/common/ConfirmationModal/ConfirmationModal";
import { toast } from "react-toastify";

interface Props {
  amount: number;
  limit: number;
  tokens: BigNumber[];
  onSuccess: () => void;
}

export const Reveal = ({
  amount,
  limit,
  tokens,
  onSuccess: handleSuccess,
}: Props) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [revealPrepare, setRevealPrepare] = useState(false);
  const { address } = useAccount();
  const { data: isApprovedForAll } = useReadApprovalState(address);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const dismissLoading = () => {
    setShowOverlay(false);
    setIsLoading(false);
  };

  const showLoading = () => {
    setShowOverlay(true);
    setIsLoading(true);
  };

  const handleSendRevealPrepare = () => {
    if (!tokens.length) {
      return;
    }
    showLoading();
    setRevealPrepare(true);
  };

  const handleSendRevealSuccess = () => {
    handleSuccess();
    setRevealPrepare(false);
    setIsSuccess(true);
    setIsLoading(false);
  };

  const handleSendRevealError = () => {
    dismissLoading();
    setRevealPrepare(false);
  };

  const { startApprove, approveIsError, approvePrepareIsError } =
    useApproveTransaction({
      onSuccess: handleSendRevealPrepare,
    });

  const handleApprove = async () => {
    if (!tokens.length) {
      return;
    }
    showLoading();
    try {
      if (startApprove) {
        await startApprove();
      }
    } catch (error) {
      console.log("Error", error);
      dismissLoading();
    }
  };

  const handleModalClose = () => {
    setIsSuccess(false);
    setShowOverlay(false);
  };

  useEffect(() => {
    if (approveIsError || approvePrepareIsError) {
      dismissLoading();
      toast.error("Approve error");
    }
  }, [approveIsError, approvePrepareIsError]);

  return (
    <>
      {showOverlay && <div className="overlay" />}
      <Container>
        <ButtonsWrapper>
          <MintedCount>
            {!tokens.length ? 0 : amount}/{`${limit}`}
          </MintedCount>

          <StyledConnectionBtn
            className="mintDesktopBtn"
            onClick={isApprovedForAll ? handleSendRevealPrepare : handleApprove}
            disabled={!tokens.length}
          >
            BREAK THEM
          </StyledConnectionBtn>
        </ButtonsWrapper>
      </Container>

      {isLoading && (
        <Notifications text="MINTING" status="loading" isPageCentered />
      )}
      {revealPrepare && (
        <SendRevealTransation
          tokens={tokens}
          onSuccess={handleSendRevealSuccess}
          onError={handleSendRevealError}
        />
      )}
      {isSuccess && <ConfirmationModal onClose={handleModalClose} />}
    </>
  );
};
