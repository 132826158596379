import { useEffect, useState } from "react";

import closeIcon from "@/assets/images/close-icon.svg";
import warningIcon from "@/assets/images/notification-warning.png";
import successIcon from "@/assets/images/notification-success.png";
import errorIcon from "@/assets/images/notification-error.png";
import loadingIcon from "@/assets/images/minting.svg";
import {
  CloseBtn,
  Icon,
  Message,
  NotificationWrapper,
} from "./Notifications.style";

export const NOTIFICATION_WIDTH_TABLET = 100;

interface Props {
  text: string;
  status: "warning" | "success" | "error" | "loading";
  fundsErr?: boolean;
  isPageCentered?: boolean;
}

export const Notifications = ({
  text,
  status,
  fundsErr,
  isPageCentered,
}: Props) => {
  const [icon, setIcon] = useState<string>();

  useEffect(() => {
    if (status === "warning") {
      setIcon(warningIcon);
    } else if (status === "success") {
      setIcon(successIcon);
    } else if (status === "error") {
      setIcon(errorIcon);
    } else if (status === "loading") {
      setIcon(loadingIcon);
    }
  }, [status]);

  return (
    <NotificationWrapper
      className="animate__animated animate__fadeIn"
      fundsErr={fundsErr}
      isPageCentered={isPageCentered}
    >
      <CloseBtn className="closeBtn">
        <img src={closeIcon} alt="close" />
      </CloseBtn>

      <Icon src={icon} alt="" className="notificationIcon" />
      <Message className="message">
        {text}
        <span className="dot1">.</span>
        <span className="dot2">.</span>
        <span className="dot3">.</span>
      </Message>
    </NotificationWrapper>
  );
};
