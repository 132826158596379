import styled, { css } from "styled-components";
import { flexCenter } from "@/assets/style/globalStyles";
import { SIZES } from "@/assets/style/mediaQueries";
import plus from "@/assets/images/plusBtn.svg";
import minus from "@/assets/images/minusBtn.svg";

const contentHeight = css`
  height: 70px;
  ${SIZES.tablet} {
    height: 45px;
  }
  ${SIZES.laptop} {
    height: 64px;
  }
`;

export const Wrapper = styled.div<{ notWhitelisted?: boolean }>`
  ${flexCenter};
  position: relative;
  z-index: 2;
  width: 282px;
  flex-wrap: wrap;
  row-gap: 20px;

  .connectBtn {
    margin: 0 auto;
  }

  .mintMobileBtn {
    width: 247px;
    height: 41px;
    font-family: "avara800";
    font-size: 15px;
    line-height: 19px;
  }

  .notificationContainer {
    position: absolute;
    transform: translateX(13px);
  }

  ${SIZES.tablet} {
    flex-wrap: nowrap;
    width: 375px;
    .notificationContainer {
      right: 0;
      left: unset;
      width: 100px;
      height: 40px;
      transform: translate(150%, -25%);
    }
  }
  ${SIZES.laptop} {
    width: 560px;
    .notificationContainer {
      width: 236px;
      height: 63px;
      transform: translateX(125%);
    }
  }
  ${({ notWhitelisted }) =>
    notWhitelisted &&
    css`
      .notificationContainer {
        right: unset;
        left: 50%;
        width: 300px;
        height: 137px;
        transform: translate(-50%, -50%);
        ${SIZES.tablet} {
          transform: translate(-50%, -55%);
          width: 215px;
        }
        ${SIZES.laptop} {
          transform: translate(-45%, -145%);
          width: 291px;
        }
        ${SIZES.desktop} {
          transform: translate(-45%, -225%);
          width: 291px;
        }
      }
    `}
`;

export const Container = styled.div<{ claim?: boolean }>`
  ${flexCenter};
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  ${contentHeight};
  ${SIZES.tablet} {
    row-gap: 2px;
    width: calc(50% - 2px);
  }
  ${SIZES.laptop} {
    width: calc(50% - 2px);
  }
`;

export const Divider = styled.div`
  background-color: #c39a6d;
  width: 2px;
  ${contentHeight};
`;

export const Label = styled.div`
  color: #824e00;
  font-size: 16px;
  line-height: 20px;
  ${SIZES.tablet} {
    font-size: 12px;
    line-height: 15px;
  }
  ${SIZES.laptop} {
    font-size: 16px;
    line-height: 20px;
  }
`;
export const Value = styled.div<{ flex?: boolean }>`
  font-family: "avara800";
  color: #824e00;
  font-size: 32px;
  line-height: 40px;
  ${({ flex }) =>
    flex &&
    css`
      ${flexCenter};
      column-gap: 12px;
      ${SIZES.tablet} {
        column-gap: 8px;
      }
    `}
  ${SIZES.tablet} {
    font-size: 22px;
    line-height: 27px;
  }
  ${SIZES.laptop} {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const AmountBtn = styled.button<{ minusBtn?: boolean }>`
  width: 28px;
  height: 28px;
  background: linear-gradient(180deg, #d69e5d 0%, #dcaa70 67.19%, #c2833a 100%);
  border: 1.34471px solid rgba(121, 61, 42, 0.25);
  box-shadow: 0px 0px 0px 1.34471px rgba(121, 61, 42, 0.75),
    0px 0px 0px 2.68941px rgba(189, 126, 65, 0.75),
    0px 0px 0px 4.03412px rgba(121, 61, 42, 0.75);
  border-radius: 0.439062px;
  ${flexCenter};
  padding: 12px 6px;
  img {
    width: 100%;
  }
  ${SIZES.tablet} {
    width: 16px;
    height: 16px;
    background: url(${({ minusBtn }) => (minusBtn ? minus : plus)});
    border: none;
    box-shadow: unset;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    border-radius: 0.653023px;
    overflow: hidden;
  }
  ${SIZES.laptop} {
    width: 26px;
    height: 26px;
  }
`;
