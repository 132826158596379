import styled, { css } from "styled-components";
import { flexCenter } from "@/assets/style/globalStyles";
import { SIZES } from "@/assets/style/mediaQueries";
import { ISize } from "./ConnectionButton";

const commonButtonStyle = css`
  ${flexCenter};
  font-size: 15px;
  line-height: 19px;
  height: 44px;
  ${SIZES.tablet} {
    font-size: 12px;
    line-height: 15px;
    height: 30px;
  }
  ${SIZES.laptop} {
    font-size: 16px;
    line-height: 20px;
    height: 38px;
  }
`;

export const StyledConnectionBtn = styled.button<{
  connectBtn?: boolean;
  responsiveWidth?: ISize;
  responsiveHeight?: ISize;
}>`
  background: linear-gradient(180deg, #d69e5d 0%, #dcaa70 67.19%, #c2833a 100%);
  border: 1.34px solid rgba(121, 61, 42, 0.25);
  box-shadow: 0px 0px 0px 2px rgba(121, 61, 42, 0.75),
    0px 0px 0px 4px rgba(189, 126, 65, 0.75),
    0px 0px 0px 6px rgba(121, 61, 42, 0.75);
  border-radius: 0.653023px;
  width: 110px;
  color: #824e00;
  ${commonButtonStyle};
  ${SIZES.tablet} {
    width: ${({ connectBtn }) => (connectBtn ? "127px" : "74px")};
  }
  ${SIZES.laptop} {
    width: ${({ connectBtn }) => (connectBtn ? "161px" : "97px")};
  }
  ${({ connectBtn }) =>
    connectBtn &&
    css`
      font-size: 16px;
      line-height: 20px;
      margin-left: auto;
    `}
  ${({ responsiveHeight, responsiveWidth }) =>
    responsiveHeight &&
    responsiveWidth &&
    css`
      height: ${responsiveHeight.mobile}px;
      width: ${responsiveWidth.mobile}px;
      ${SIZES.tablet} {
        height: ${responsiveHeight.tablet}px;
        width: ${responsiveWidth.tablet}px;
      }
      ${SIZES.laptop} {
        height: ${responsiveHeight.laptop}px;
        width: ${responsiveWidth.laptop}px;
      }
    `}
`;

export const WalletAddress = styled.div`
  background: radial-gradient(
    60.71% 60.71% at 50% 50%,
    #a5703f 0%,
    #8e633e 74.08%,
    #9c6c3f 100%
  );
  border: 4px solid #734e35;
  box-shadow: 0px 0px 0px 2px rgba(218, 166, 103, 0.75);
  border-radius: 1px;
  width: 198px;
  margin-right: 20px;
  color: #ffe9d4;
  ${commonButtonStyle};
  ${SIZES.tablet} {
    margin-left: auto;
    width: 139px;
  }
  ${SIZES.laptop} {
    margin-left: auto;
    width: 174px;
  }
`;

export const ModalButton = styled.button`
  font-family: "avara800";
  background: linear-gradient(180deg, #d69e5d 0%, #dcaa70 67.19%, #c2833a 100%);
  border: 1.34px solid rgba(121, 61, 42, 0.25);
  box-shadow: 0px 0px 0px 2px rgba(121, 61, 42, 0.75),
    0px 0px 0px 4px rgba(189, 126, 65, 0.75),
    0px 0px 0px 6px rgba(121, 61, 42, 0.75);
  border-radius: 2px;
  width: 100%;
  color: #824e00;
  font-weight: 800;
  ${flexCenter};
  font-size: 15px;
  line-height: 19px;
  height: 48px;
  ${SIZES.tablet} {
    font-size: 12px;
    line-height: 15px;
    height: 40px;
  }
  ${SIZES.laptop} {
    font-size: 16px;
    line-height: 20px;
    height: 54px;
  }
`;
