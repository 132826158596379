export const MINT_CONTRACT_ADDRESS =
  "0x62aE32c10D0bbAa3D8222856816a22AC4C98b4De";
export const REVEALER_CONTRACT_ADDRESS =
  "0x2eb7cf2f4c2d0d3e9bdf8DD942354443fb2822ED";
export const ALCHEMY_ID = "J2Oq9u9MVtlzjbwl5EbXxOKwin5BR2RB";

// TESTNET ADDRESSES (mumbai)
// export const MINT_CONTRACT_ADDRESS =
//   "0xb58630fea67f66193e03a148b0a92f964218059b";
// export const REVEALER_CONTRACT_ADDRESS =
//   "0x2153798669dc2aE6E5d78E5bB55E8690e0597B04";
// export const ALCHEMY_ID = "5AUxmA6rZ_YOhb4ns2bL06lsQDnlryL-";
