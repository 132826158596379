import { useMediaQuery } from "usehooks-ts";
import dividerMobile from "@/assets/images/footer-divider-mobile.svg";
import dividerDesktop from "@/assets/images/footer-divider-desktop.svg";
import twitter from "@/assets/images/twitter.png";
import discord from "@/assets/images/discord.png";
import {
  Copyright,
  Divider,
  FirstRow,
  FollowUs,
  MiddleRow,
  Socials,
  StyledFooter,
} from "./Footer.style";

export const Footer = () => {
  const isTablet = useMediaQuery("(min-width: 768px)");

  return (
    <StyledFooter>
      <FollowUs>
        {isTablet ? (
          <FirstRow>
            <div className="text">Follow us</div>
            <Divider>
              <img src={dividerDesktop} alt="" className="decorator" />
            </Divider>
          </FirstRow>
        ) : (
          <>
            <div className="text">Follow us</div>
            <Divider>
              <img src={dividerMobile} alt="" />
            </Divider>
          </>
        )}
        {isTablet ? (
          <MiddleRow>
            <SocialMedia />
            <div className="middleInfo">
              “The walls of Sparta were its young men, and its borders the
              points of their spears.” <br /> – Agesilaus, Spartan King
            </div>
          </MiddleRow>
        ) : (
          <SocialMedia />
        )}
      </FollowUs>
      <Copyright>
        <div style={{ display: "flex", gap: 6 }}>
          <div className="spartadex-c">©</div>
          <div className="spartadex">SpartaDEX {new Date().getFullYear()}</div>
        </div>
        <div className="rights">All rights reserved</div>
      </Copyright>
    </StyledFooter>
  );
};

const SocialMedia = () => {
  return (
    <Socials>
      <a
        href="https://twitter.com/spartadex_io"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={twitter} alt="twitter" />
      </a>
      <a
        href="http://discord.gg/spartadex"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={discord} alt="discord" />
      </a>
    </Socials>
  );
};
