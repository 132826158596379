import { WagmiConfig, createClient } from "wagmi";
import { ConnectKitProvider, getDefaultClient } from "connectkit";
import { arbitrum } from "wagmi/chains";
import { ALCHEMY_ID } from "@/constants/constants";

type Web3ProviderProps = {
  children?: React.ReactNode;
};

const client = createClient(
  getDefaultClient({
    appName: "Sparta Dex Minting",
    alchemyId: ALCHEMY_ID,
    chains: [arbitrum],
  })
);

export const Web3Provider = ({ children }: Web3ProviderProps) => {
  return (
    <WagmiConfig client={client}>
      <ConnectKitProvider>{children}</ConnectKitProvider>
    </WagmiConfig>
  );
};
