import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '@/components/App';
import { LoadingProvider } from './context/LoadingState';
import '@/assets/fonts/avara/index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <LoadingProvider>
      <App />
    </LoadingProvider>
  </React.StrictMode>
);
