import styled, { css } from "styled-components";
import { flexCenter } from "@/assets/style/globalStyles";
import { SIZES } from "@/assets/style/mediaQueries";

const mintFont = css`
  font-family: "avara800";
  font-size: 12px;
  line-height: 15px;
  ${SIZES.laptop} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Container = styled.div<{ claim?: boolean }>`
  width: 100%;
  ${SIZES.tablet} {
    width: calc(50% - 2px);
  }
  ${SIZES.laptop} {
    width: calc(50% - 2px);
  }
`;
export const ButtonsWrapper = styled.div`
  ${flexCenter};
  margin: 0 auto;
  .mintDesktopBtn {
    border-radius: 2px;
    width: 175px;
    height: 40px;
    ${mintFont};
    ${SIZES.tablet} {
      width: 108px;
      height: 33px;
    }
    ${SIZES.laptop} {
      width: 151px;
      height: 54px;
    }
  }
`;

export const MintedCount = styled.div`
  background: radial-gradient(
    60.71% 60.71% at 50% 50%,
    #a5703f 0%,
    #8e633e 74.08%,
    #9c6c3f 100%
  );
  border: 4px solid #734e35;
  box-shadow: 0px 0px 0px 3px rgba(218, 166, 103, 0.75);
  border-radius: 1px;
  width: 54px;
  height: 40px;
  color: #ffe9d4;
  ${mintFont};
  ${flexCenter};
  ${SIZES.tablet} {
    width: 44px;
    height: 33px;
  }
  ${SIZES.laptop} {
    width: 59px;
    height: 54px;
  }
`;
