import styled, { css } from 'styled-components';
import { flexCenter } from '@/assets/style/globalStyles';
import { SIZES } from '@/assets/style/mediaQueries';

export const Wrapper = styled.main`
  max-width: 100vw;
`;
export const ImgContainer = styled.div`
  position: relative;
  height: 510px;
  ${flexCenter};
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 35px;
  max-width: 100%;
  ${SIZES.tablet} {
    height: 335px;
    padding-bottom: 26px;
  }
  ${SIZES.laptop} {
    height: 516px;
    padding-bottom: 0;
  }
  ${SIZES.desktop} {
    height: 734px;
  }
`;

export const Spartan = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 348px;
  ${SIZES.tablet} {
    height: 277px;
  }
  ${SIZES.laptop} {
    height: 452px;
  }
  ${SIZES.desktop} {
    height: 728px;
  }
`;

export const DocumentContainer = styled.div<{claim?: boolean}>`
  position: relative;
  z-index: 2;
  width: 351px;
  height: 234px;
  ${flexCenter};
  ${SIZES.tablet} {
    width: ${({claim}) => claim ? "312px" : "430px"};
    height: 119px;
    ${({claim}) => claim && css`
    padding: 0px 20px;
    .documentImg {
      height: ${claim ? "100%" : "auto"};
    }
    `}
  }
  ${SIZES.laptop} {
    width: ${({claim}) => claim ? "465px" : "639px"};
    height: 176px;
  }
`;
export const Document = styled.img`
  position: absolute;
  left: -12px;
  top: -26px;
  ${SIZES.tablet} {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
`;
