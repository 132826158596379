import { flexCenter } from '@/assets/style/globalStyles';
import { SIZES } from '@/assets/style/mediaQueries';
import styled from 'styled-components';

export const StyledHeader = styled.header`
  position: relative;
  width: 100%;
  .logo {
    width: 60px;
  }
  .content {
    ${flexCenter};
    width: 100%;
    padding: 28px 22px 0;
    ${SIZES.tablet} {
      padding: 15px 45px 15px 58px;
    }
    ${SIZES.laptop} {
      padding: 22px 0;
      margin: 0 auto;
      width: 1052px;
    }
    ${SIZES.desktop} {
      padding: 20px 0;
      width: 1080px;
      .logo {
        width: 116px;
      }
    }
  }
`;

export const BorderDecorator = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  ${SIZES.laptop} {
    width: 1315px;
  }
`;
