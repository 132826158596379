import { flexCenter } from "@/assets/style/globalStyles";
import { SIZES } from "@/assets/style/mediaQueries";
import styled from "styled-components";

import modalBg from "@/assets/images/modal-background.svg";
import modalBgTablet from "@/assets/images/modal-background-tablet.svg";
import modalBgMobile from "@/assets/images/modal-background-mobile.svg";

export const Container = styled.div`
  width: 240px;
  height: 400px;
  background: url(${modalBgMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px 25px;
  margin: 0 auto;
  border-radius: 8px;
  z-index: 9999;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${SIZES.tablet} {
    background: url(${modalBgTablet});
    width: 330px;
    height: 189px;
    top: 30%;
  }

  ${SIZES.laptop} {
    background: url(${modalBg});
    width: 460px;
    height: 264px;
  }
`;

export const Title = styled.div`
  font-family: "avara800";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 127.5%;
  color: #96683f;
  text-align: center;
  text-transform: uppercase;
  ${SIZES.tablet} {
    font-size: 12px;
  }

  ${SIZES.laptop} {
    font-size: 19px;
  }
`;

export const Message = styled.div`
  font-family: "avara700";
  font-size: 14px;
  line-height: 127.5%;
  color: #96683f;
  text-align: left;
  background: transparent;
  ${SIZES.tablet} {
    font-size: 12px;
  }

  ${SIZES.laptop} {
    font-size: 16px;
  }
`;

export const ButtonsContainer = styled.div`
  width: calc(100% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 15px;
  flex-direction: column;
  ${SIZES.tablet} {
    flex-direction: row;
  }
`;

export const CloseBtn = styled.button`
  width: 13px;
  height: 13px;
  ${flexCenter};
  position: absolute;
  right: 14px;
  top: 14px;
  background: transparent;

  ${SIZES.tablet} {
    width: 20px;
    height: 20px;
    right: 6px;
    top: 4px;
    transform: scale(0.7);
  }

  ${SIZES.laptop} {
    width: 13px;
    height: 13px;
    transform: scale(1);
    right: 14px;
    top: 14px;
  }
`;
