import { useEffect } from "react";
import { useAccount } from "wagmi";
import { useMediaQuery } from "usehooks-ts";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";

import { useReadUserTokens } from "@/services/useReadContract";
import plus from "@/assets/images/plus.svg";
import minus from "@/assets/images/minus.svg";
import { useRevealState } from "@/context/RevealState";

import { ConnectionButton } from "@/components/common";
import { Reveal } from "./Reveal/Reveal";
import {
  Wrapper,
  Container,
  Divider,
  Label,
  Value,
  AmountBtn,
} from "./DocumentContent.style";

export const DocumentContent = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isTablet = useMediaQuery("(min-width: 768px)");
  const { isConnected, address } = useAccount();
  const { amount, setAmount, decrease, increase, maxAmount, setMaxAmount } =
    useRevealState();
  const { data: userTokens, refetch } = useReadUserTokens(address);

  useEffect(() => {
    if (userTokens?.length && userTokens.length < maxAmount) {
      setMaxAmount(userTokens.length);
    }
  }, [userTokens, maxAmount, setMaxAmount]);

  const handleRefetch = () => {
    refetch();
    setAmount(1);
  };

  return (
    <Wrapper>
      {isConnected ? (
        <>
          <Container>
            <Label>Available to break</Label>
            <Value flex>
              <AmountBtn
                disabled={amount <= 1}
                onClick={decrease}
                minusBtn={isTablet}
              >
                {!isTablet && <img src={minus} alt="decrease" />}
              </AmountBtn>
              {!userTokens?.length ? 0 : amount}
              <AmountBtn
                disabled={amount === maxAmount || !userTokens?.length}
                onClick={increase}
              >
                {!isTablet && <img src={plus} alt="increase" />}
              </AmountBtn>
            </Value>
          </Container>
          {!isMobile && <Divider />}

          {isTablet && !isMobile && <Divider />}

          <Reveal
            amount={amount}
            limit={userTokens ? userTokens.length : 0}
            tokens={userTokens ? userTokens.slice(0, amount) : []}
            onSuccess={handleRefetch}
          />
        </>
      ) : (
        <ConnectionButton
          responsiveWidth={{ mobile: 247, tablet: 117, laptop: 175 }}
          responsiveHeight={{ mobile: 41, tablet: 32, laptop: 48 }}
        />
      )}
      <ReactNotifications className="notificationContainer" />
    </Wrapper>
  );
};
