import { useAccount, useDisconnect } from 'wagmi';
import { ConnectKitButton } from 'connectkit';
import { StyledConnectionBtn } from './WalletButtons.style';

export interface ISize {
  mobile: number;
  tablet: number;
  laptop: number;
}
interface Props {
  responsiveWidth?: ISize;
  responsiveHeight?: ISize;
  hide?: boolean;
}

export const ConnectionButton = ({ hide, responsiveWidth, responsiveHeight }: Props) => {
  const { isConnected} = useAccount()
  const { disconnect } = useDisconnect();

  return hide && !isConnected ? null : (
    <>
    <ConnectKitButton.Custom>
    {({ isConnected, show }) => {
        return (
           <StyledConnectionBtn
           className='connectBtn'
           responsiveWidth={responsiveWidth}
           responsiveHeight={responsiveHeight}
           connectBtn={!isConnected}
           onClick={() => isConnected ? disconnect() : show ? show() : undefined}>
           {isConnected ? 'Log Out' : 'connect wallet'}
         </StyledConnectionBtn>
        );
      }}
    </ConnectKitButton.Custom>
        </>
  );
};
