import { useRevealTokens } from "@/services/useRevealTokens";
import { BigNumber } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

type Props = {
  tokens: BigNumber[];
  onSuccess: () => void;
  onError: () => void;
};

export const SendRevealTransation = ({ tokens, onSuccess, onError }: Props) => {
  const [isSended, setIsSended] = useState(false);
  const { startReveal, revealIsError, revealPrepareIsError } = useRevealTokens({
    tokens,
    onSuccess,
    onError,
  });

  const handleReveal = useCallback(async () => {
    try {
      if (startReveal) {
        await startReveal();
      }
    } catch (error) {
      console.log("Error", error);
      onError();
    }
  }, [startReveal, onError]);

  useEffect(() => {
    if (startReveal && !isSended) {
      handleReveal();
      setIsSended(true);
    }
  }, [startReveal, handleReveal, isSended]);

  useEffect(() => {
    if (revealIsError || revealPrepareIsError) {
      onError();
      toast.error("Transaction error");
    }
  }, [revealIsError, revealPrepareIsError, onError]);

  return null;
};
