import { useMediaQuery } from "usehooks-ts";
import spartaHolder from "@/assets/images/sparta-holder.png";
import document from "@/assets/images/document.png";
import documentMobile from "@/assets/images/document-mobile.png";
import { DocumentContent } from "./DocumentContent/DocumentContent";
import {
  Wrapper,
  ImgContainer,
  Spartan,
  Document,
  DocumentContainer,
} from "./Main.style";

export const Main = () => {
  const isTablet = useMediaQuery("(min-width: 768px)");

  return (
    <Wrapper>
      <ImgContainer>
        <Spartan src={spartaHolder} alt="spartan" />
        <DocumentContainer>
          <DocumentContent />
          {isTablet ? (
            <Document src={document} alt="" className="documentImg" />
          ) : (
            <Document src={documentMobile} alt="" />
          )}
        </DocumentContainer>
      </ImgContainer>
    </Wrapper>
  );
};
