import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import {
  MINT_CONTRACT_ADDRESS,
  REVEALER_CONTRACT_ADDRESS,
} from "@/constants/constants";
import minterAbi from "@/assets/abi/spartansAbi.json";

type ApproveTransactionProps = {
  onSuccess: () => void;
};

export const useApproveTransaction = ({
  onSuccess,
}: ApproveTransactionProps) => {
  const prepareWrite = usePrepareContractWrite({
    address: MINT_CONTRACT_ADDRESS,
    abi: minterAbi,
    functionName: "setApprovalForAll",
    args: [REVEALER_CONTRACT_ADDRESS, true],
  });

  const approve = useContractWrite(prepareWrite.config);

  const approveTx = useWaitForTransaction({
    hash: approve?.data?.hash,
    enabled: !!approve?.data?.hash,
    onSuccess,
  });

  const isLoading = approve.isLoading || approveTx.isLoading;
  const isError = approve.isError || approveTx.isError;
  const isSuccess = approve.isSuccess || approveTx.isSuccess;

  return {
    startApprove: approve.write,
    approveLoading: isLoading,
    approveIsError: isError,
    approvePrepareIsError: prepareWrite.isError,
    approveSuccess: isSuccess,
    error: approve.error,
  };
};
