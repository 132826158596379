import styled from "styled-components";
import { flexCenter } from "@/assets/style/globalStyles";
import { SIZES } from "@/assets/style/mediaQueries";

export const StyledFooter = styled.footer`
  ${flexCenter};
  flex-direction: column;
  justify-content: space-between;
  padding: 132px 27.45px 38px;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  ${SIZES.tablet} {
    padding: 62px 42px 40px;
  }
  ${SIZES.laptop} {
    padding: 121px 144px 85px;
  }
  ${SIZES.desktop} {
    padding: 265px 0 74px;
  }
`;

export const FollowUs = styled.div`
  ${flexCenter};
  flex-direction: column;
  width: 100%;
  .text {
    font-family: "avara800";
    font-size: 25px;
    line-height: 31px;
  }
  ${SIZES.laptop} {
    .text {
      font-size: 35px;
      line-height: 43px;
    }
  }
  ${SIZES.desktop} {
    .text {
      font-size: 55px;
      line-height: 68px;
    }
  }
`;

export const FirstRow = styled.div`
  ${flexCenter};
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  column-gap: 80px;
  ${SIZES.laptop} {
    margin-bottom: 78px;
  }
  ${SIZES.desktop} {
    margin-bottom: 95px;
  }
`;

export const Socials = styled.div`
  ${flexCenter};
  column-gap: 27px;
  img {
    cursor: pointer;
    width: 86px;
  }
  ${SIZES.tablet} {
    column-gap: 16px;
    img {
      width: 51px;
    }
  }
  ${SIZES.laptop} {
    column-gap: 20px;
    img {
      width: 66px;
    }
  }
  ${SIZES.desktop} {
    column-gap: 27px;
    img {
      width: 86px;
    }
  }
`;

export const Divider = styled.div`
  margin-top: 9px;
  margin-bottom: 30px;
  width: 320px;
  ${SIZES.tablet} {
    flex-grow: 1;
    height: 1.33px;
    background-color: #fff;
    margin: 0;
    position: relative;
    .decorator {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-100%, -50%);
    }
  }
`;

export const MiddleRow = styled.div`
  ${flexCenter};
  justify-content: space-between;
  width: 100%;
  .middleInfo {
    text-align: right;
    max-width: 255px;
    font-size: 12px;
    line-height: 26px;
  }
  ${SIZES.laptop} {
    .middleInfo {
      max-width: 410px;
      font-size: 19px;
    }
  }
`;

export const Copyright = styled.div`
  margin-right: auto;
  margin-top: 135px;
  .spartadex-c {
    font-family: "Futura";
  }
  .spartadex {
    font-weight: 300;
    font-size: 19px;
    line-height: 26px;
    margin-bottom: 8px;
  }
  .rights {
    font-size: 19px;
    line-height: 26px;
  }
  ${SIZES.tablet} {
    margin-top: 39px;
    ${flexCenter};
    justify-content: space-between;
    width: 100%;
    .spartadex,
    .rights {
      font-size: 12px;
    }
    .spartadex {
      margin-bottom: 0;
    }
  }
  ${SIZES.laptop} {
    margin-top: 107px;
    .spartadex,
    .rights {
      font-size: 19px;
    }
  }
`;
