import { useContractRead } from "wagmi";
import { BigNumber } from "ethers";
import minterAbi from "@/assets/abi/spartansAbi.json";
import {
  MINT_CONTRACT_ADDRESS,
  REVEALER_CONTRACT_ADDRESS,
} from "@/constants/constants";

export const useReadApprovalState = (address: `0x${string}` | undefined) => {
  return useContractRead<any, any, boolean>({
    address: MINT_CONTRACT_ADDRESS,
    abi: minterAbi,
    functionName: "isApprovedForAll",
    args: [address, REVEALER_CONTRACT_ADDRESS],
  });
};

export const useReadUserTokens = (address: `0x${string}` | undefined) => {
  return useContractRead<any, any, BigNumber[]>({
    address: MINT_CONTRACT_ADDRESS,
    abi: minterAbi,
    functionName: "tokensOfOwner",
    args: [address],
  });
};
