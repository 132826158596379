import { createGlobalStyle, css } from 'styled-components';
import polis from '@/assets/images/polis.png';
import polisDesktop from '@/assets/images/polis-desktop.png';
import { SIZES } from './mediaQueries';

export const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    scroll-behavior: smooth;
  }

  body, #root{
    height: 100vh;
    width: 100%;
    font-family: 'avara700', sans-serif;
    background-color: #1E1E1E;
    color: #fff;
  }

  #root {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;
    position: relative;
    overflow: hidden auto;
    background-image: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 42.4%), url(${polis});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    ${SIZES.tablet} {
      background-image: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 42.4%), url(${polisDesktop});
    }
  }

  a {
    color: inherit;
    border: none;
    text-decoration: none;
  }

  ul, ol {
    list-style: none;
  }
  li {
    cursor: pointer;
  }

  button {
    display: block;
    font-family: 'avara700';
    font-size: 16px;
    line-height: 20px;
    color: #824E00;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: none;
  }

  button:disabled {
    opacity: 1;
    cursor: not-allowed;
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00000087;
    z-index: 1000;
  }
  .loader svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
